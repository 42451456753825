import { useState, useContext } from 'react';
import { t } from "i18next";
import { useSnackbar } from "notistack";
import { useNavigate } from 'raviger';
import useGlobal from "store/store";
//mui
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { Box, IconButton, SwipeableDrawer, Typography, } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
//assets
import emptyCart from "../../assets/emptyCart.svg"
//components
import { ReuqestsContainer } from "Components/Cart/FirstStep";
import { BottomContainer, CashText, ContinueButton } from "Components/Cart/Balance";
import { GreenButton } from "Components/Register/AddAnotherParent";
import Product from "Components/Cart/Product";
//api
import { addRegAPI, getRegAPI } from "api/api";
//context
import { TribeIsUpdatedContext } from "context/tribeIsUpdateContext";
import GenericDialog from 'Components/Common/GenericDIalog';
import { detectDeviceType } from 'actions/actions';

const Header = styled(Box)(() => ({
    display: 'flex',
    paddingLeft: '2rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#f0f3f0 !important'
}));

const useStyles = makeStyles(() => ({
    orangeBG: {
        backgroundColor: "#ffbb41 !important"
    },
    redBG: {
        backgroundColor: "#ff6645 !important"
    }

}));
const OpenActivitiesToReg = ({ child, activities, openDrawer, setDrawer }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [childActivs, setChildActivs] = useState(activities);
    const [openDialog, setOpenDialog] = useState(false);

    const { reqsUpdate, setReqsUpdaet } = useContext(TribeIsUpdatedContext);
    const [household] = useGlobal((state) => state.family);
    const [family, setFamily] = useGlobal((state) => state.family, (actions) => actions.setFamily);

    const navigate = useNavigate();

    const onOpen = () => { setDrawer(true); }
    const onClose = () => { setDrawer(false); }

    const selectionDone = () => {
        setReqsUpdaet(reqsUpdate + 1);
        setOpenDialog(true);
    }

    const navToHomePage = () => {
        setOpenDialog(false);
        onClose();
        setFamily(null);
        navigate(`/homepage`);
    }
    const onCloseDialog = () => {
        setOpenDialog(false);
        onClose();
    }

    const onAddRegReq = async (item, index) => {
        const activity = structuredClone(item);
        const { data } = await getRegAPI({ StudentCardCode: child.CardCode, ItmsGrpCod: '160', PeriodCode: activity.PeriodCode });

        if (data[0]?.Status_request === "4" || data[0]?.Status_request === "2" || activity.ItmsGrpCod === "160" || activity.ItemPurposeType === "6") {
            const regRegToCreate = {
                Status_request: "2", //new request by tribe ( not by parent )
                ParentCardCode: household.CardCode,
                Parent1_Mail: household.Parent1_Mail,
                Parent1_ID: household.Parent1_ID,
                Parent1_FirstName: household.Parent1_FirstName,
                Parent1_LastName: household.Parent1_LastName,
                Parent2_Mail: household.Parent2_Mail,
                Parent2_ID: household.Parent2_ID,
                Parent2_FirstName: household.Parent2_FirstName,
                Parent2_LastName: household.Parent2_LastName,
                LicTradNum: child.LicTradNum,
                Pupil_FirstName: child.Pupil_FirstName,
                Pupil_lastName: child.Pupil_lastName,
                Class: child.Class,
                FinalPrice: activity.DiscPrice, //initialize final price field 
                isTribeUserOpen: true,
                UserDeviceType: detectDeviceType(),
                ...activity
            }
            try {
                const createdReg = await addRegAPI(regRegToCreate)
                activity._id = createdReg._id;
                // re render new array
                const newArray = [...childActivs];
                newArray[index] = activity;
                setChildActivs(newArray);
                enqueueSnackbar("בקשת רישום נוצרה בהצלחה", { className: classes.orangeBG });

            } catch (error) {
                console.log(error)
            }
        }
        else {
            enqueueSnackbar("עלייך להוסיף קודם מיסי חבר", { className: classes.redBG });
        }
    }

    const totalAmount = childActivs.reduce((a, c) => a + (+c.DiscPrice), 0);

    return (<><SwipeableDrawer
        PaperProps={{
            sx: {
                background: '#f0f3f0',
                left: 'auto',
                right: 0,
                height: '100%',
                width: {
                    xs: '90%',
                    sm: '70%',
                    md: '40%',
                    lg: '35%',
                    xl: '30%'
                }
            }
        }}
        ModalProps={{
            keepMounted: false
        }}
        disableBackdropTransition={true}
        disableSwipeToOpen={true}
        open={openDrawer}
        onOpen={e => onOpen()}
        onClose={e => onClose()}
        dir='rtl'
        anchor="right"
    >
        <Box display='flex' flexDirection='column' overflow="auto" height='100%'>
            <Header>
                {!!childActivs.length ?
                    <Typography sx={{ fontSize: { xs: "1rem", lg: "1.5rem" }, fontWeight: "bold" }} color='#000000'>
                        {`${t("activityRegisterList")} (${childActivs.length})`}</Typography> :
                    <Typography sx={{ fontSize: { xs: "1rem", lg: "1.5rem" }, fontWeight: "bold" }} color='#000000'>
                        {`${t('activityRegisterList')}`}</Typography>}
                <IconButton onClick={e => onClose()} >
                    <CloseIcon sx={{ color: "#3a7735" }} />
                </IconButton>
            </Header>
            <ReuqestsContainer>
                {<Typography sx={{ color: "#3a7735", fontSize: "1rem", textAlign: "center" }}>{t("addRegistrationByClick")}</Typography>}
                {!!childActivs.length ?
                    childActivs.map((item, index) => {
                        return <Product key={item.ActivCode} props={item} active={true} deleteOption={false}
                            extraButton={<GreenButton
                                sx={{ ...item?._id && { background: '#d1d1d1 !important' }, margin: '0 !important', padding: '0 1rem', width: { xs: '8rem !important', md: 'auto !important' }, hieght: 'auto !important' }}
                                disabled={!!item?._id}
                                onClick={() => onAddRegReq(item, index)} >
                                {t('registrationReq')}
                            </GreenButton>} />
                    }) :
                    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                        <img
                            alt={"..."}
                            src={emptyCart}
                            width="270px"
                            height="320px"
                        />
                        <Typography sx={{ color: "#3a7735", fontWeight: "bold", fontSize: "1.4rem", textAlign: "center" }}>{t("noActForChild")}</Typography>
                        <Typography sx={{ color: "#3a7735", fontSize: "1.4rem", textAlign: "center" }}>{t("noActForChild1")}</Typography>
                    </Box>
                }
            </ReuqestsContainer>
            <BottomContainer sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                <Box display="flex" justifyContent='space-between' paddingBottom='0.5rem' textAlign={"left"}>
                    <Box display="flex" flexDirection='column' paddingLeft='0.5rem' >
                        <Typography color={'#000000'}> {t('sum')} ({childActivs.length} {t('activities')})</Typography>
                    </Box>
                    <Box display="flex" flexDirection='column' paddingRight={'0.5rem'} alignItems={'end'}>
                        <CashText color={'#000000'}>{"\u20AA"} {totalAmount} </CashText>
                    </Box>
                </Box>
                <Box display="flex" alignItems='center' justifyContent="center">
                    <ContinueButton disabled={!childActivs.length}
                        onClick={selectionDone}
                        aria-label="close">
                        {t("closeAndContinue")}
                        <ArrowBackIosNewIcon />
                    </ContinueButton>
                </Box>
            </BottomContainer >

        </Box>
    </SwipeableDrawer >
        {
            openDialog && <GenericDialog open={openDialog} setOpen={setOpenDialog} showRopes={false} showExit={false}>
                <Typography sx={{ color: "#064601", fontWeight: "bold", fontSize: "1.2rem" }}>{t("doneWithFamily")}</Typography>
                <Typography sx={{ textAlign: "center" }}>{t("doneWithFamily1")}</Typography>
                <Typography sx={{ textAlign: "center" }}>{t("doneWithFamily2")}</Typography>
                <Box display="flex" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                    <GreenButton negative="true" onClick={e => navToHomePage()}>{t('yes')}</GreenButton>
                    <GreenButton onClick={e => onCloseDialog()} >{t('no')}</GreenButton>
                </Box>
            </GenericDialog>
        }</>
    )
}
export default OpenActivitiesToReg;