import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import useGlobal from "../../store/store";
//libs
import { format } from 'date-fns'
import Switch from "react-switch";
//mui
import { Box, Card, Chip, CardHeader, CardContent, Typography } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import SubtitlesOffOutlinedIcon from '@mui/icons-material/SubtitlesOffOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
//api
import { addRegAPI } from 'api/api';
//data
import { permanentInfo } from 'data/permanentInfo';
//components
import { activityUseStyles } from "Components/TribeComponents/ActivityItem"
import { CustomBusy } from 'Components/Common/CustomBusy';
import { dateFormat, MuiTypography, datePast } from "Components/TribeComponents/ActivityItem";
import { detectDeviceType } from 'actions/actions';

const GeshemActivities = ({ activities, addProduct, household }) => {
    const classes = activityUseStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [openSummerNote, setOpenSummerNote] = useState(false);
    const [busy, setBusy] = useState(false);
    const [cartState, setCartState] = useGlobal((state) => state.cartState, (actions) => actions.setCartState);
    const [householdSelections, [removeHouseholdSelection, addHouseholdSelection]] = useGlobal((state) => state.householdSelections,
        (actions) => ([actions.removeHouseholdSelection, actions.addHouseholdSelection]));

    const handleCart = async (e) => {
        setBusy(true);
        if (e) { //activity selected
            try {

                const regReqs = await addRegistrationRequest();

                for (const regReq of regReqs) {
                    addHouseholdSelection(regReq);
                    addProduct(regReq);
                }
                if (regReqs.some((el) => el.isSummerAct)) {
                    setOpenSummerNote(true);
                }
                // setCartState(true);
                const key = enqueueSnackbar(t('itemAddedSuccessfuly'), { variant: "success" });
                setTimeout(() => { closeSnackbar(key) }, 1000)
            } catch (error) {
                enqueueSnackbar(error?.message || t("Error"), { className: classes.redBG });
            } finally {
                setBusy(false);
            }
        }
        else { //activity unselected
            try {
                for (const activity of activities) {
                    removeHouseholdSelection(activity);
                } //remove from household selections
            } catch (error) {
                console.log(error);
            } finally {
                setBusy(false);
            }
        }
    };
    const addRegistrationRequest = async () => {
        const regArray = [];
        const child = household.ChildrenList.results.find(c => { return c.CardCode === activities[0].StudentCardCode });
        for (const activity of activities) {
            const regRegToCreate = {
                Status_request: "1", //new request
                ParentCardCode: household.CardCode,
                Parent1_Mail: household.Parent1_Mail,
                Parent1_ID: household.Parent1_ID,
                Parent1_FirstName: household.Parent1_FirstName,
                Parent1_LastName: household.Parent1_LastName,
                Parent2_Mail: household.Parent2_Mail,
                Parent2_ID: household.Parent2_ID,
                Parent2_FirstName: household.Parent2_FirstName,
                Parent2_LastName: household.Parent2_LastName,
                LicTradNum: child.LicTradNum,
                Pupil_FirstName: child.Pupil_FirstName,
                Pupil_lastName: child.Pupil_lastName,
                Class: child.Class,
                FinalPrice: activity.DiscPrice, //initialize final price field 
                UserDeviceType: detectDeviceType(),
                ...activity
            }
            try {
                const createdReg = await addRegAPI(regRegToCreate)
                regRegToCreate._id = createdReg._id;
                regRegToCreate.selected = true;
                regArray.push(regRegToCreate);

            } catch (error) {
                console.log(error)
            }
        }
        return regArray;
    }
    const geshemFather = activities.find(activ => !activ.Father);
    const geshemChildren = activities.filter(activ => activ.Father && !datePast(activ.EndReg));
    const endReg = dateFormat(geshemFather.EndReg);
    const disabeldEndDate = geshemFather.EndReg;
    const switchDisabled = geshemFather.isOverlapped || geshemFather.disabled || datePast(disabeldEndDate);
    const selected = !!geshemFather._id;
    const checkBoxVisble =
        <Switch
            checked={selected}
            onChange={e => handleCart(e)}
            handleDiameter={29}
            offColor={switchDisabled ? "#ecfceb" : "#3a7735"}
            onColor="#ffbb41"
            offHandleColor="#fff"
            onHandleColor="#fff"
            disabled={switchDisabled}
            height={25}
            width={100}
            borderRadius={50}
            activeBoxShadow='none'
            className={`${classes.switch} ${selected ? classes.selectBorder : classes.unSelectBorder} ${switchDisabled && classes.noBorder} ${switchDisabled && classes.grayBG}`}
            uncheckedIcon={
                <Box>
                    {
                        geshemFather.isOverlapped ?
                            <Typography sx={{ fontSize: "0.8rem", textAlign: 'center' }} >{t("overlappedActivity")}</Typography> :
                            datePast(disabeldEndDate) ?
                                <Typography sx={{ fontSize: "0.75rem", textAlign: 'center' }} >{t("registrationClosedShort")}</Typography> :
                                geshemFather.disabled ?
                                    <Typography sx={{ fontSize: "0.8rem", textAlign: 'center' }}>{t("blocked")}</Typography>
                                    :
                                    <Typography sx={{ fontSize: "0.8rem", color: "#ffffff", paddingRight: "13px", fontWeight: "bold" }}>{t("addToCartShort")}</Typography>
                    }
                </Box>
            }
            uncheckedHandleIcon={
                <Box alignItems='center' display='flex' justifyContent='center'>
                    {switchDisabled ?
                        <SubtitlesOffOutlinedIcon sx={{ color: "#fff", width: "0.8em" }} />
                        :
                        busy ? <CustomBusy small={true} /> : <AddShoppingCartOutlinedIcon sx={{ color: "#3a7735", width: "0.8em" }} />}
                </Box>
            }
            checkedIcon={
                <Typography sx={{ fontSize: "0.8rem", color: "#3a7735", paddingRight: "4px", fontWeight: "bold" }}>{t("activityInCart")}</Typography>
            }
            checkedHandleIcon={
                <Box alignItems='center' display='flex' justifyContent='center' >
                    {busy ? <CustomBusy small={true} /> : <DeleteIcon sx={{ color: "#3a7735", width: "0.8em" }} />}
                </Box>
            }
        />
    const totalSum = <Typography sx={{ fontWeight: "bold" }}>{`${t("sum")} ${geshemChildren.reduce((accum, act) => accum + (parseInt(act.DiscPrice) || 0), 0)} ${"\u20AA"}`}</Typography>
    return (
        <Card sx={{ position: 'relative', backgroundColor: "#f0f3f0 !important", padding: "0.5rem" }} className={`${classes.activityCard} ${(geshemFather.isOverlapped || geshemFather.disabled || datePast(disabeldEndDate)) && classes.disabledItem} ${selected && classes.itemSelected}`}>
            <CardHeader sx={{ padding: '0.25rem 1rem' }} title={geshemFather?.ActivName} />
            <CardContent sx={{ flex: 1, padding: '0.25rem 1rem' }}>
                <Box display='flex' alignItems="flex-start" minHeight="17%" flexDirection="column">
                    <Chip label={`${t("classOf")} ${permanentInfo.childClasses[geshemFather?.Class]}'`} size="small" className={classes.ActivChip} />
                    {endReg && <Box display='flex' width={'100%'} alignItems={'center'} minHeight="17%">
                        <MuiTypography marginLeft='0.2rem' variant="activityAlert" color="alert">
                            {t("registrationEnd")} {endReg}
                        </MuiTypography>
                    </Box>}
                </Box>

            </CardContent>
            {geshemChildren.map((act) => {
                return (
                    <GeshemItem props={act} key={act.ActivCode} />)
            })}
            <Box display='flex' alignItems="center" justifyContent="space-between" flexDirection={{ xs: "column", sm: "row" }}>
                {totalSum}
                {checkBoxVisble}
            </Box>
        </Card>
    )
}
const GeshemItem = ({ props }) => {
    return (
        <Card key={props.ActivCode} sx={{ borderRadius: "20px", margin: "0.5rem", boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.16)" }}>
            <CardContent>
                <Box display='flex' alignItems={'center'} justifyContent='space-between' flexDirection='column'>
                    <CardHeader sx={{ padding: '0.25rem 1rem' }} title={props.ActivName} />
                    <Box display='flex' flexDirection='row'>
                        {!!props.EndRegDate &&
                            <Box display='flex' alignItems={'center'}>
                                <EventIcon fontSize='0.9rem' />
                                <Typography marginLeft='.25rem' variant="activityTxt">
                                    {format(new Date(props.EndRegDate), 'dd/MM/yyyy')}
                                </Typography>
                            </Box>}
                        <Box display='flex' alignItems={'center'}>
                            <Typography marginLeft='.25rem' variant="activityTxt" sx={{ fontWeight: "bold" }}>
                                {"\u20AA"}{props.DiscPrice}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card >
    )
}
export default GeshemActivities