import { removeRegAPI } from "../api/api";

export const addProduct = async (store, product) => {
    const updatedCart = store.state.products.concat(product); // add object to end of array
    updatePrice(store, updatedCart);
    store.setState({ products: updatedCart });

};
export const setProducts = (store, products) => {
    updatePrice(store, products);
    store.setState({ products });
}
export const getProducts = (store) => {
    return store.state.products;
}
export const removeProduct = async (store, product, fromCart = false) => {
    const updatedCart = store.state.products.filter((currProduct) => currProduct._id !== product._id);
    if (updatedCart.length === 0 && !!store.state.cartFamily && store.state.user.role === 'tribe') // release family disable
    {
        setCartFamily(store, '');
    }
    updatePrice(store, updatedCart);
    const updateState = fromCart ? { products: updatedCart, checkRemove: !store.state.checkRemove } : { products: updatedCart }
    store.setState(updateState);
};

// for tribe user only
export const clearProducts = async (store) => {
    setProducts(store, [])
    setCartFamily(store, '');

};
export const setProductsToAdd = (store, productsToAdd) => {
    updatePrice(store, productsToAdd);
    store.setState({ productsToAdd });
}
export const setCartState = (store, cartState) => {
    store.setState({ cartState });
};
export const setCartFamily = (store, cartFamily) => {
    store.setState({ cartFamily })
};
const updatePrice = (store, products) => {
    let total = 0;
    products.map(prod => {
        if (prod.ItemPurposeType === "4" && !prod.Father) {
            prod.FinalPrice = 0;
            prod.DiscPrice = 0;
        }
        total += parseFloat(store.state.user.role === 'parent' ? prod.DiscPrice : (prod.FinalPrice - prod.paid));
    })
    store.setState({ total });
};
export const setUser = (store, user) => {
    store.setState({ user });
}

//sets current household details which uses the app
export const setHousehold = (store, household) => {
    store.setState({ household });
}
export const setParentSettings = (store, parentSettings) => {
    store.setState({ parentSettings });
}
//set selections array
export const setHouseholdSelections = (store, aSelections) => {
    store.setState({ householdSelections: aSelections });
}
//adds selected activity to selections array
export const addHouseholdSelection = (store, obj) => {

    const household = store.state.household;

    let childIndex = household.ChildrenList.results.findIndex(c => (c.CardCode === obj.StudentCardCode));

    const childActivities = household.ChildrenList.results[childIndex].ChildFactoriesPortal.results;

    const childrenUpdateActivities = childActivities.map(activ => activ.ActivCode === obj.ActivCode ? obj : activ);

    household.ChildrenList.results[childIndex].ChildFactoriesPortal.results = childrenUpdateActivities;

    const updatedSelections = store.state.householdSelections.concat([]);
    store.setState({ householdSelections: updatedSelections, household });
}
//removes activity from selections array and from db
export const removeHouseholdSelection = async (store, remove) => {
    const activitiesToRemove = [];
    const household = store.state.household;
    let childIndex = household.ChildrenList.results.findIndex(c => (c.CardCode === remove.StudentCardCode));
    const childActivities = household.ChildrenList.results[childIndex].ChildFactoriesPortal.results;

    //collect activities to unselect & remove from child & cart
    for (let i = 0; i < childActivities.length; i++) {
        const activity = childActivities[i];

        //activity to remove from child
        if (activity.ActivCode === remove.ActivCode) {
            activitiesToRemove.push(activity);
        }
        //annual fee for current year removed, rest of activities of current cannot be purchased
        if (remove.ItemPurposeType === "2" && activity.ActivCode !== remove.ActivCode && activity.Class === remove.Class && activity.ItemPurposeType !== "6") {
            activitiesToRemove.push(activity);
        }
    }
    //remove and unselect
    for (let i = 0; i < activitiesToRemove.length; i++) {
        const activity = activitiesToRemove[i];
        //remove from selections which were added to cart and stored in db and unselect
        try {
            if (activity._id) { //registration request is in db, remove it
                await removeRegAPI(activity); //remove from db
                delete activity._id; //eliminate db id and status
                delete activity.Status_request
            }
        } catch (error) {
            console.error(error);
        }
    }

    //unselect activities which were not added to cart yet
    let updatedSelections = store.state.householdSelections;
    updatedSelections = updatedSelections.filter(remove => !activitiesToRemove.find(rm => (remove.ActivCode === rm.ActivCode)))

    //remove from cart not needed products
    let updatedProducts = store.state.products;
    updatedProducts = updatedProducts.filter(remove => !activitiesToRemove.find(rm => (remove.ActivCode === rm.ActivCode && remove.StudentCardCode === rm.StudentCardCode)))

    setProducts(store, updatedProducts);

    store.setState({
        householdSelections: updatedSelections,
        household: store.state.household
    });
}
export const setLanguage = (store, language) => {
    store.setState({ language });
}
export const setIsLoggedIn = (store, isLoggedIn) => {
    store.setState({ isLoggedIn });
}
export const setSearchState = (store, searchState) => {
    store.setState({ searchState });
}
export const setCheckRemove = (store, checkRemove) => {
    store.setState({ checkRemove });
}
export const setCreditCardApproval = (store, creditCardApproval) => {
    store.setState({ creditCardApproval });
}
export const setGuestPayment = (store, guestPayment) => {
    store.setState({ guestPayment });
}
export const setFinalPrice = (store, finalPrice) => {
    store.setState({ finalPrice });
}
export const setMessageBox = (store, messageBoxState) => {
    store.setState({ messageBoxState });
}
export const setPayByExtra = (store, payByExtra) => {
    store.setState({ payByExtra });
}
export const setPayWithAllExtra = (store, payWithAllExtra) => {
    store.setState({ payWithAllExtra });
}

export const setOpenActivities = (store, openActivities) => {
    store.setState({ openActivities });
}
export const setTotalOpenAct = (store, totalOpenAct) => {
    store.setState({ totalOpenAct });
}
export const setOpenRequests = (store, openRequests) => {
    store.setState({ openRequests });
}
export const setDeepLink = (store, deepLink) => {
    store.setState({ deepLink })
};
export const setTribePaid = (store, tribePaid) => {
    store.setState({ tribePaid })
};
export const setActivCode = (store, activCode) => {
    store.setState({ activCode })
};
export const setParentRegs = (store, parentRegs) => {
    store.setState({ parentRegs })
};
export const setUTDRequests = (store, UTDRequests) => {
    store.setState({ UTDRequests })
};
export const setUserAccess = (store, userAccess) => {
    store.setState({ userAccess })
};
export const setAccessibiltyTermsDialog = (store, accessibiltyTermsDialog) => {
    store.setState({ accessibiltyTermsDialog })
};
export const setLoginIdProcess = (store, LoginIdProcess) => {
    store.setState({ LoginIdProcess })
};

export const setFamily = (store, family) => {
    store.setState({ family })
};
export const setChild = (store, child) => {
    store.setState({ child })
};
export const setEmailExsist = (store, emailExsist) => {
    store.setState({ emailExsist })
};
export const setEmailEmpty = (store, emailEmpty) => {
    store.setState({ emailEmpty })
};
export const setActivitiesList = (store, activitiesList) => {
    store.setState({ activitiesList })
};
export const setUnlockActivityDrawer = (store, unlockActivityDrawer) => {
    store.setState({ unlockActivityDrawer })
};
export const setUpdatePupilDrawer = (store, updatePupilDrawer) => {
    store.setState({ updatePupilDrawer })
};
export const setActivityToUnlock = (store, activityToUnlock) => {
    store.setState({ activityToUnlock })
};
export const setTroopClass = (store, troopClass) => {
    store.setState({ troopClass })
};
export const setPupilsToUpdate = (store, pupilsToUpdate) => {
    store.setState({ pupilsToUpdate })
};

export const detectDeviceType = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    switch (true) {
        // Mobile Phones: Prioritize mobile check first
        case userAgent.includes("mobile") || userAgent.includes("iphone") ||
            (userAgent.includes("android") && userAgent.includes("mobile")):
            return "mobile";

        // Tablets: iPads and other tablets (checking android without mobile)
        case userAgent.includes("ipad") || userAgent.includes("tablet") ||
            (userAgent.includes("android") && !userAgent.includes("mobile")):
            return "tablet";

        // Desktop: Only check if not mobile or tablet
        case userAgent.includes("windows") || userAgent.includes("macintosh") ||
            (userAgent.includes("linux") && !userAgent.includes("android")):
            return "desktop";

        // Default fallback
        default:
            return "unknown";
    }
};
